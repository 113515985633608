import { NotificationContainer } from "@abb/abb-common-ux-react";
import { useNotification } from "../../app_context/NotificationContext";

const Notification = () => {
  const { notification, showNotification } = useNotification();
  return (
    <NotificationContainer
      notifications={notification}
      style={{ position: "fixed", top: "0px", right: "0px" }}
      actionHandler={{
        remove: () => showNotification([]),
      }}
    />
  );
};

export default Notification;

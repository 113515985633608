// NotificationContext.js
import { createContext, useState, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
const {
  REACT_APP_URL_PREFIX,
  REACT_APP_ENV_PREFIX,
  REACT_APP_DOMAIN,
  REACT_APP_LOCATION,
  REACT_APP_KEYCLOAK,
} = process.env;
const HMIContext = createContext<any>(null);

console.log(
  REACT_APP_URL_PREFIX,
  REACT_APP_ENV_PREFIX,
  REACT_APP_DOMAIN,
  REACT_APP_LOCATION,
  REACT_APP_KEYCLOAK,
  "ENV Variables in HMI Context"
);
export const HMIContextProvider = (props: any) => {
  const { children, defaultAppURL } = props;
  const navigate = useNavigate();
  const hostURL = `${REACT_APP_URL_PREFIX}${REACT_APP_ENV_PREFIX}${REACT_APP_LOCATION}${REACT_APP_DOMAIN}`;
  console.log(hostURL, 'hostURL')
  const side_props = {
    home_url: "home",
    my_apps_url: "my_apps",
    page_name: "test1",
    applications: [
      {
        name: `Services`,
        id: 1,
        url: "/service_apps",
        icon: "/assets/images/icon_services.svg",
        icon_active: "/assets/images/icon_services_active.svg",
        sub_menu: [],
      },
      {
        name: `Extended Solutions`,
        id: 2,
        url: `/extensions`,
        icon: `/assets/images/icon_core_extension.svg`,
        icon_active: `/assets/images/icon_core_extension_active.svg`,
        sub_menu: [],
      },
      {
        name: `Platform Administration`,
        id: 3,
        url: `/platform_administration`,
        icon: `/assets/images/icon_platform_administration.svg`,
        icon_active: `/assets/images/icon_platform_administration_active.svg`,
        sub_menu: [
          {
            name: `Platform Administration`,
            id: 3,
            url: `/platform_administration`,
            icon: `/assets/images/icon_platform_administration.svg`,
            icon_active: `/assets/images/icon_platform_administration_active.svg`,
            sub_menu: [],
          },
          {
            name: `Roles & User Management`,
            id: 4,
            url: `/platform_administration/roles_user_management`,
            icon: `/assets/images/icon_roles.svg`,
            icon_active: `/assets/images/icon_roles_active.svg`,
            sub_menu: [
              {
                name: `Platform Administration`,
                id: 3,
                url: `/platform_administration`,
                icon: `/assets/images/icon_platform_administration.svg`,
                icon_active: `/assets/images/icon_platform_administration_active.svg`,
                sub_menu: [],
              },
              {
                name: `Roles & User Management`,
                id: 4,
                url: `/platform_administration/roles_user_management`,
                icon: `/assets/images/icon_roles.svg`,
                icon_active: `/assets/images/icon_roles_active.svg`,
                sub_menu: [],
              },
              {
                name: `Role Definition`,
                id: 1,
                url: `/platform_administration/roles_user_management/role_definition`,
                icon: `/assets/images/icon_role_definitions.svg`,
                icon_active: `/assets/images/icon_role_definition_active.svg`,
                sub_menu: [
                  {
                    name: `Platform Administration`,
                    id: 3,
                    url: `/platform_administration`,
                    icon: `/assets/images/icon_platform_administration.svg`,
                    icon_active: `/assets/images/icon_platform_administration_active.svg`,
                    sub_menu: [],
                  },
                  {
                    name: `Roles & User Management`,
                    id: 4,
                    url: `/platform_administration/roles_user_management`,
                    icon: `/assets/images/icon_roles.svg`,
                    icon_active: `/assets/images/icon_roles_active.svg`,
                    sub_menu: [],
                  },
                  {
                    name: `Role Definition`,
                    id: 1,
                    url: `/platform_administration/roles_user_management/role_definition`,
                    icon: `/assets/images/icon_role_definitions.svg`,
                    icon_active: `/assets/images/icon_role_definition_active.svg`,
                    sub_menu: [],
                  },
                  {
                    name: `User Mapping And Role`,
                    id: 1,
                    url: `/platform_administration/roles_user_management/user_mapping`,
                    icon: `/assets/images/icon_user_mapping.svg`,
                    icon_active: `/assets/images/icon_user_mapping_active.svg`,
                    sub_menu: [],
                  },
                  {
                    name: `User Requests`,
                    id: 1,
                    url: `/platform_administration/roles_user_management/user_requests`,
                    icon: "/assets/images/icon_folder.svg",
                    icon_active: "/assets/images/icon-folder-active.svg",
                    sub_menu: [],
                  },
                ],
              },
              {
                name: `User Mapping And Role`,
                id: 1,
                url: `/platform_administration/roles_user_management/user_mapping`,
                icon: `/assets/images/icon_user_mapping.svg`,
                icon_active: `/assets/images/icon_user_mapping_active.svg`,
                sub_menu: [
                  {
                    name: `Platform Administration`,
                    id: 3,
                    url: `/platform_administration`,
                    icon: `/assets/images/icon_platform_administration.svg`,
                    icon_active: `/assets/images/icon_platform_administration_active.svg`,
                    sub_menu: [],
                  },
                  {
                    name: `Roles & User Management`,
                    id: 4,
                    url: `/platform_administration/roles_user_management`,
                    icon: `/assets/images/icon_roles.svg`,
                    icon_active: `/assets/images/icon_roles_active.svg`,
                    sub_menu: [],
                  },
                  {
                    name: `Role Definition`,
                    id: 1,
                    url: `/platform_administration/roles_user_management/role_definition`,
                    icon: `/assets/images/icon_role_definitions.svg`,
                    icon_active: `/assets/images/icon_role_definition_active.svg`,
                    sub_menu: [],
                  },
                  {
                    name: `User Mapping And Role`,
                    id: 1,
                    url: `/platform_administration/roles_user_management/user_mapping`,
                    icon: `/assets/images/icon_user_mapping.svg`,
                    icon_active: `/assets/images/icon_user_mapping_active.svg`,
                    sub_menu: [],
                  },
                  {
                    name: `User Requests`,
                    id: 1,
                    url: `/platform_administration/roles_user_management/user_requests`,
                    icon: "/assets/images/icon_folder.svg",
                    icon_active: "/assets/images/icon-folder-active.svg",
                    sub_menu: [],
                  },
                ],
              },
              {
                name: `User Requests`,
                id: 1,
                url: `/platform_administration/roles_user_management/user_requests`,
                icon: "/assets/images/icon_folder.svg",
                icon_active: "/assets/images/icon-folder-active.svg",
                sub_menu: [
                  {
                    name: `Platform Administration`,
                    id: 3,
                    url: `/platform_administration`,
                    icon: `/assets/images/icon_platform_administration.svg`,
                    icon_active: `/assets/images/icon_platform_administration_active.svg`,
                    sub_menu: [],
                  },
                  {
                    name: `Roles & User Management`,
                    id: 4,
                    url: `/platform_administration/roles_user_management`,
                    icon: `/assets/images/icon_roles.svg`,
                    icon_active: `/assets/images/icon_roles_active.svg`,
                    sub_menu: [],
                  },
                  {
                    name: `Role Definition`,
                    id: 1,
                    url: `/platform_administration/roles_user_management/role_definition`,
                    icon: `/assets/images/icon_role_definitions.svg`,
                    icon_active: `/assets/images/icon_role_definition_active.svg`,
                    sub_menu: [],
                  },
                  {
                    name: `User Mapping And Role`,
                    id: 1,
                    url: `/platform_administration/roles_user_management/user_mapping`,
                    icon: `/assets/images/icon_user_mapping.svg`,
                    icon_active: `/assets/images/icon_user_mapping_active.svg`,
                    sub_menu: [],
                  },
                  {
                    name: `User Requests`,
                    id: 1,
                    url: `/platform_administration/roles_user_management/user_requests`,
                    icon: "/assets/images/icon_folder.svg",
                    icon_active: "/assets/images/icon-folder-active.svg",
                    sub_menu: [],
                  },
                ],
              },
            ],
          },
          {
            name: `Activity Logs`,
            id: 1,
            url: `/platform_administration/activity_logs`,
            icon: "/assets/images/icon_activity_logs.svg",
            icon_active: "/assets/images/icon_activity_logs_active.svg",
            sub_menu: [
              {
                name: `Platform Administration`,
                id: 3,
                url: `/platform_administration`,
                icon: `/assets/images/icon_platform_administration.svg`,
                icon_active: `/assets/images/icon_platform_administration_active.svg`,
                sub_menu: [],
              },
              {
                name: `Activity Logs`,
                id: 1,
                url: `/platform_administration/activity_logs`,
                icon: "/assets/images/icon_activity_logs.svg",
                icon_active: "/assets/images/icon_activity_logs_active.svg",
                sub_menu: [],
              },
            ],
          },
          {
            name: `Application Provisioning`,
            id: 1,
            url: `/platform_administration/manage_applications`,
            icon: `/assets/images/icon_application_provisioning.svg`,
            icon_active: `/assets/images/icon_application_provisioning_active.svg`,
            sub_menu: [
              {
                name: `Platform Administration`,
                id: 3,
                url: `/platform_administration`,
                icon: `/assets/images/icon_platform_administration.svg`,
                icon_active: `/assets/images/icon_platform_administration_active.svg`,
                sub_menu: [],
              },
              {
                name: `Manage Applications`,
                id: 3,
                url: `/platform_administration/manage_applications`,
                icon: `/assets/images/icon_platform_administration.svg`,
                icon_active: `/assets/images/icon_platform_administration_active.svg`,
                sub_menu: [],
              },
              {
                name: `Application Provisioning`,
                id: 1,
                url: `/platform_administration/manage_applications/Application_Provisioning`,
                icon: `/assets/images/icon_application_provisioning.svg`,
                icon_active: `/assets/images/icon_application_provisioning_active.svg`,
                sub_menu: [
                  {
                    name: `Platform Administration`,
                    id: 3,
                    url: `/platform_administration`,
                    icon: `/assets/images/icon_platform_administration.svg`,
                    icon_active: `/assets/images/icon_platform_administration_active.svg`,
                    sub_menu: [],
                  },
                  {
                    name: `Manage Applications`,
                    id: 3,
                    url: `/platform_administration/manage_applications`,
                    icon: `/assets/images/icon_platform_administration.svg`,
                    icon_active: `/assets/images/icon_platform_administration_active.svg`,
                    sub_menu: [],
                  },
                  {
                    name: `Application Provisioning`,
                    id: 1,
                    url: `/platform_administration/manage_applications/Application_Provisioning`,
                    icon: `/assets/images/icon_application_provisioning.svg`,
                    icon_active: `/assets/images/icon_application_provisioning_active.svg`,
                    sub_menu: [],
                  },
                  {
                    name: `Import/Export Application`,
                    id: 1,
                    url: `/platform_administration/manage_applications/Import_Export_Application`,
                    icon: `/assets/images/icon_import_export.svg`,
                    icon_active: `/assets/images/icon_import_export_active.svg`,
                    sub_menu: [],
                  },
                ],
              },
              {
                name: `Import/Export Application`,
                id: 1,
                url: `/platform_administration/manage_applications/Import_Export_Application`,
                icon: `/assets/images/icon_import_export.svg`,
                icon_active: `/assets/images/icon_import_export_active.svg`,
                sub_menu: [],
              },
            ],
          },
          {
            name: `Session Control`,
            id: 1,
            url: `/platform_administration/session_control`,
            icon: `/assets/images/icon_session_control.svg`,
            icon_active: `/assets/images/icon_session_control_active.svg`,
            sub_menu: [
              {
                name: `Platform Administration`,
                id: 3,
                url: `/platform_administration`,
                icon: `/assets/images/icon_platform_administration.svg`,
                icon_active: `/assets/images/icon_platform_administration_active.svg`,
                sub_menu: [],
              },
              {
                name: `Session Control`,
                id: 1,
                url: `/platform_administration/session_control`,
                icon: `/assets/images/icon_session_control.svg`,
                icon_active: `/assets/images/icon_session_control_active.svg`,
                sub_menu: [],
              },
            ],
          },
        ],
      },
      {
        name: `hide`,
        id: 1,
        url: `/platform_administration/roles_user_management/user_mapping/assign_role`,
        icon: ``,
        icon_active: ``,
        sub_menu: [
          {
            name: `Platform Administration`,
            id: 3,
            url: `/platform_administration`,
            icon: `/assets/images/icon_platform_administration.svg`,
            icon_active: `/assets/images/icon_platform_administration_active.svg`,
            sub_menu: [],
          },
          {
            name: `Roles & User Management`,
            id: 4,
            url: `/platform_administration/roles_user_management`,
            icon: `/assets/images/icon_roles.svg`,
            icon_active: `/assets/images/icon_roles_active.svg`,
            sub_menu: [],
          },
          {
            name: `Role Definition`,
            id: 1,
            url: `/platform_administration/roles_user_management/role_definition`,
            icon: `/assets/images/icon_role_definitions.svg`,
            icon_active: `/assets/images/icon_role_definition_active.svg`,
            sub_menu: [],
          },
          {
            name: `User Mapping And Role`,
            id: 1,
            url: `/platform_administration/roles_user_management/user_mapping`,
            icon: `/assets/images/icon_user_mapping.svg`,
            icon_active: `/assets/images/icon_user_mapping_active.svg`,
            sub_menu: [],
          },
          {
            name: `User Requests`,
            id: 1,
            url: `/platform_administration/roles_user_management/user_requests`,
            icon: "/assets/images/icon_folder.svg",
            icon_active: "/assets/images/icon-folder-active.svg",
            sub_menu: [],
          },
        ],
      },
      {
        name: `hide`,
        id: 1,
        url: `/platform_administration/roles_user_management/user_mapping/invite_new_user`,
        icon: ``,
        icon_active: ``,
        sub_menu: [
          {
            name: `Platform Administration`,
            id: 3,
            url: `/platform_administration`,
            icon: `/assets/images/icon_platform_administration.svg`,
            icon_active: `/assets/images/icon_platform_administration_active.svg`,
            sub_menu: [],
          },
          {
            name: `Roles & User Management`,
            id: 4,
            url: `/platform_administration/roles_user_management`,
            icon: `/assets/images/icon_roles.svg`,
            icon_active: `/assets/images/icon_roles_active.svg`,
            sub_menu: [],
          },
          {
            name: `Role Definition`,
            id: 1,
            url: `/platform_administration/roles_user_management/role_definition`,
            icon: `/assets/images/icon_role_definitions.svg`,
            icon_active: `/assets/images/icon_role_definition_active.svg`,
            sub_menu: [],
          },
          {
            name: `User Mapping And Role`,
            id: 1,
            url: `/platform_administration/roles_user_management/user_mapping`,
            icon: `/assets/images/icon_user_mapping.svg`,
            icon_active: `/assets/images/icon_user_mapping_active.svg`,
            sub_menu: [],
          },
          {
            name: `User Requests`,
            id: 1,
            url: `/platform_administration/roles_user_management/user_requests`,
            icon: "/assets/images/icon_folder.svg",
            icon_active: "/assets/images/icon-folder-active.svg",
            sub_menu: [],
          },
        ],
      },
      {
        name: `hide`,
        id: 1,
        url: `/platform_administration/roles_user_management/user_mapping//assignUserRole`,
        icon: ``,
        icon_active: ``,
        sub_menu: [
          {
            name: `Platform Administration`,
            id: 3,
            url: `/platform_administration`,
            icon: `/assets/images/icon_platform_administration.svg`,
            icon_active: `/assets/images/icon_platform_administration_active.svg`,
            sub_menu: [],
          },
          {
            name: `Roles & User Management`,
            id: 4,
            url: `/platform_administration/roles_user_management`,
            icon: `/assets/images/icon_roles.svg`,
            icon_active: `/assets/images/icon_roles_active.svg`,
            sub_menu: [],
          },
          {
            name: `Role Definition`,
            id: 1,
            url: `/platform_administration/roles_user_management/role_definition`,
            icon: `/assets/images/icon_role_definitions.svg`,
            icon_active: `/assets/images/icon_role_definition_active.svg`,
            sub_menu: [],
          },
          {
            name: `User Mapping And Role`,
            id: 1,
            url: `/platform_administration/roles_user_management/user_mapping`,
            icon: `/assets/images/icon_user_mapping.svg`,
            icon_active: `/assets/images/icon_user_mapping_active.svg`,
            sub_menu: [],
          },
          {
            name: `User Requests`,
            id: 1,
            url: `/platform_administration/roles_user_management/user_requests`,
            icon: "/assets/images/icon_folder.svg",
            icon_active: "/assets/images/icon-folder-active.svg",
            sub_menu: [],
          },
        ],
      },
    ],
    contact_abb: "/contact_abb",
    onLinkClick: (url: string, newTab?: boolean) => {
      if (newTab) {
        window.open(url, "_blank");
      } else {
        navigate(url);
      }
    },
  };

  const header_props = {
    application_name: `My Pulp and Paper`,
    my_apps: [],
    auth_url: `${hostURL}/authservapi/api/Auth/GetTokenByAADCode`,
    auth_apis: undefined,
    refresh_ep: `RefreshAccessToken`,
    logout_ep: `${hostURL}/authservapi/api/Auth/Logout`,
    getToken: () => console.log("first"),
  };
  const REACT_APP_ENV_DOMAIN = `${REACT_APP_ENV_PREFIX}${REACT_APP_KEYCLOAK}${REACT_APP_LOCATION}${REACT_APP_DOMAIN}`;

  const suffix = `/realms/MyPulpAndPaper/protocol/openid-connect/auth?response_type=code&client_id=ServicePro`;
  console.log(REACT_APP_ENV_DOMAIN, "REACT_APP_ENV_DOMAIN");
  console.log(
    `https://${REACT_APP_ENV_DOMAIN}${suffix}&redirect_uri=${window.location.href}home`,
    "HMIContext-524"
  );
  const login_props = {
    landing: `home`,
    keycloaks: undefined,
    suffix: suffix,
    keycloak_url: REACT_APP_ENV_DOMAIN,
    defaultAppURL: defaultAppURL,
    getUserPreferencedetails: `${hostURL}/provisioningapi/api/UserPreference/getUserPreference`,
  };
  const footer_props = {
    copyright: `Copyright 2024 - 25 ABB`,
    tzname: `UTC`,
    utc_offset: `+00:00`,
    tz_ep: `${hostURL}/provisioningapi/api/UserPreference/getUserPreferenceTimeLocale`,
  };

  const userPreference_props = {
    localTime: [],
    allApplicationType: [],
    timezoneSelected: "UTC",
    usertime_ep: `${hostURL}/provisioningapi/api/UserPreference/getUserPreferenceTimeLocale`,
    alltime_ep: `${hostURL}/provisioningapi/api/UserPreference/getAllTimeZone`,
    allCategoryApplications: `${hostURL}/provisioningapi/api/ApplicationConfiguration/getAllApplicationsGroupedByCategory`,
    createUserPreference: `${hostURL}/provisioningapi/api/UserPreference/createUserPreference`,
    getUserPreference: `${hostURL}/provisioningapi/api/UserPreference/getUserPreference`,
    getSessionTimeoutvalue: `${hostURL}/authservapi/api/PlatformAdmin/GetSessionTimeoutvalue`,
  };
  const hmi_props = {
    header_props: header_props,
    side_props: side_props,
    login_props: login_props,
    footer_props: footer_props,
    userPreference_props: userPreference_props,
    urls: {
      auth_url: `${hostURL}/authservapi/api/Auth/GetTokenByAADCode`,
      refresh_url: "RefreshAccessToken",
      logout_url: `${hostURL}/authservapi/api/Auth/Logout`,
    },
    landing: `home`,
    keycloaks: undefined,
    suffix: suffix,
    keycloak_url: REACT_APP_ENV_DOMAIN,
  };
  const [HMIData, setHMIData] = useState<any>(hmi_props);

  const contextValue = useMemo(
    () => ({ HMIData, setHMIData }),
    [HMIData, setHMIData]
  );
  return (
    <HMIContext.Provider value={contextValue}>{children}</HMIContext.Provider>
  );
};

export const useHMI = () => useContext(HMIContext);

import React, { useEffect, useState } from "react";
import { AppGeneralTextContext } from "./app_context/app_context";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  Dropdown,
  DropdownOption,
  Icon,
  Input,
} from "@abb/abb-common-ux-react";
import { MyPnPDataGrid } from "pnp-components-library";
import {
  getAllApplicationByCategory,
  getAllApplications,
  getAllStatuses,
  getUserRequests,
  rejectApplication,
} from "./services/myPnpServices";
import { AllApplications } from "./role_definition";
import { useNotification } from "./app_context/NotificationContext";
import { buildNotification } from "./utils/utils";
import Modal, { modalVarientEnum } from "./components/common/GenericModal";
import { getUserDataFromToken } from "./utils/buildnotify";
import { MUIButton } from "@abb/abb-arcadia-ui-react";

const NewUserRequest = () => {
  const appGeneralTextData = React.useContext(AppGeneralTextContext);
  const { general_text_data }: any = appGeneralTextData;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  const [filterData, setFilterData] = React.useState<any>({
    firstname: "",
    lastname: "",
    email: "",
    applicationName: "",
    category: "",
    status: "",
    priorSixMonths: false,
  });

  const [allStatus, setAllStatus] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [userRequestData, setUserRequestData] = useState<any>([]);
  const [allCategory, setAllCategory] = useState<AllApplications[]>();
  const [applicationList, setApplicationList] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>();
  const { showNotification } = useNotification();
  const [selectedRow, setSelectedRow] = useState<any>();
  const user_data = getUserDataFromToken() || null;
  useEffect(() => {
    getStatuses();
    getPgageData();
    callGetAllApplications();
  }, []);

  useEffect(() => {
    getPgageData();
  }, [filterData.priorSixMonths]);

  const callGetAllApplications = async () => {
    try {
      const resp = await getAllApplications();

      if (resp.length > 0) {
        const modifiedRes = resp.map((item: any) => {
          return {
            value: item.client_id,
            label: item.client_Name,
          };
        });
        let applications: any[] = resp;
        setAllCategory(applications);
        setSelectedCategory(modifiedRes[1]);
        fetchApplications(modifiedRes[1].value);
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const fetchApplications = async (value: any) => {
    try {
      const result = await getAllApplicationByCategory(2);
      if (result.length > 0) {
        setApplicationList(result);
      }
    } catch (err) {
      showNotification([
        buildNotification("Failed to get an application", "alarm"),
      ]);
    }
  };

  const getStatuses = async () => {
    try {
      const response = await getAllStatuses();
      const modifiedRes = response.map((item: any) => {
        return {
          value: item.statusId,
          label: item.statusName,
        };
      });
      setAllStatus(modifiedRes);
      setFilterData({ ...filterData, status: modifiedRes[0] });
    } catch (err) {}
  };
  const getPgageData = async () => {
    const payload = {
      userName: filterData.email || "",
      firstName: filterData.firstname || "",
      lastName: filterData.lastname || "",
      appRegId: filterData.applicationName.value,
      statusId: filterData.status.value || 1,
      priorSixMonths: filterData.priorSixMonths,
    };
    const response = await getUserRequests(payload);
    setUserRequestData(response.data);
  };

  const ActionLink = (value: any) => {
    const rowData = value.data;
    return (
      <div className="action-link">
        <span
          onClick={() =>
            navigate("/platform_administration/user_mapping/assignUserRole", {
              state: {
                email: rowData.username,
                applicationName: rowData.applicationName,
                id: rowData.id,
                clientId: selectedCategory.value,
              },
            })
          }
        >
          {general_text_data.approve}
        </span>
        |
        <span
          onClick={() => {
            setSelectedRow(rowData);
            setIsModalOpen(true);
          }}
        >
          {general_text_data.reject}
        </span>
      </div>
    );
  };
  const onPageNumClick = (pageNumber: number) => {
    setCurrentPage(pageNumber + 1);
  };

  const handleRejectRequest = async () => {
    const payload = {
      userRequestId: selectedRow.id,
      approverName: user_data?.name,
      isRejected: true,
    };
    try {
      const result = await rejectApplication(payload);
      if (result) {
        showNotification([buildNotification(result.data, "success")]);
        getPgageData();
        setIsModalOpen(false);
      }
    } catch (err) {
      showNotification([
        buildNotification("Failed to reject application request", "alarm"),
      ]);
    }
  };
  const columns = (isActionRequired: boolean) => {
    return [
      {
        title: "User Name",
        field: "firstName",
        flex: 1,
      },
      {
        title: "User Last Name",
        field: "lastName",
        flex: 1,
      },

      {
        title: "Email",
        field: "username",
        flex: 1,
      },
      {
        field: "applicationName",
        flex: 1,
      },
      {
        field: "status",
        flex: 1,
      },

      {
        field: "comment",
        flex: 1,
      },

      ...(isActionRequired
        ? []
        : [
            {
              field: "Process",
              title: "Approve / Reject",
              flex: 1,
              cellRenderer: ActionLink,
            },
          ]),
    ];
  };

  return (
    <div className="new-user-request-container">
      <div className="role-deinition-screen">
        <h1>{general_text_data.user_requests}</h1>
        <div className="filter-section">
          <div className="grid-container gap-20">
            <div className="col-1">
              <div className="filter-button">
                <Icon name="abb/filter" sizeClass="small" />
                <div className="filter-label">
                  {general_text_data.filter_by}
                </div>
              </div>
            </div>

            <div className="col-3">
              <label className="filter-label">
                {general_text_data.first_name}
              </label>

              <Input
                type="normal"
                dataType="text"
                placeholder="First Name"
                value={filterData.firstname || ""}
                onValueChange={(val) =>
                  setFilterData({ ...filterData, firstname: val })
                }
                className="input-width-90"
              />
            </div>
            <div className="col-3">
              <label className="filter-label">
                {general_text_data.last_name}
              </label>

              <Input
                type="normal"
                dataType="text"
                placeholder="Last Name"
                value={filterData.lastname || ""}
                onValueChange={(val) =>
                  setFilterData({ ...filterData, lastname: val })
                }
                className="input-width-90"
              />
            </div>
            <div className="col-3">
              <label className="filter-label">
                {general_text_data.email} :
              </label>

              <Input
                type="normal"
                dataType="text"
                placeholder="email"
                value={filterData.email || ""}
                onValueChange={(val) =>
                  setFilterData({ ...filterData, email: val })
                }
                className="input-width-90"
              />
            </div>
          </div>
          <div className="grid-container gap-20 mt-20">
            <div className="col-1"></div>
            <div className="col-3">
              <div className="filter-fields">
                <label className="filter-label">
                  {general_text_data.category} :
                </label>
                <Dropdown
                  value={selectedCategory || null}
                  multiselect={false}
                  placeholder="Select Category"
                  disabled
                >
                  {allCategory?.map((e: any) => (
                    <DropdownOption
                      key={e.applicationCategoryId}
                      label={e.client_Name}
                      value={e.applicationCategoryId}
                    />
                  ))}
                </Dropdown>
              </div>
            </div>

            <div className="col-3">
              <label className="filter-label">
                {general_text_data.application_name} :
              </label>

              <Dropdown
                onChange={(val: any) =>
                  setFilterData({ ...filterData, applicationName: val[0] })
                }
                value={filterData.applicationName || null}
                multiselect={false}
                placeholder="Select Application"
                className="filter-fields"
              >
                {applicationList?.length > 0
                  ? applicationList?.map((e: any) => (
                      <DropdownOption
                        key={e.appRegId}
                        label={e.applicationName}
                        value={e.appRegId}
                      />
                    ))
                  : null}
              </Dropdown>
            </div>
            <div className="col-3">
              <label className="filter-label">
                {general_text_data.status} :
              </label>

              <Dropdown
                onChange={(val: any) =>
                  setFilterData({ ...filterData, status: val[0] })
                }
                value={filterData.status ? filterData.status : ""}
                multiselect={false}
                placeholder="Select Application"
                className="filter-fields"
              >
                {allStatus?.map((e: any) => (
                  <DropdownOption
                    key={e.value}
                    label={e.label}
                    value={e.value}
                  />
                ))}
              </Dropdown>
            </div>
            <div className="col-2">
              <div className="apply-button">
                <MUIButton
                  label={general_text_data.apply}
                  onClickButton={() => getPgageData()}
                  size={32}
                  variant="primary"
                  className="mt-20 "
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-20">
          <div className="d-flex-justify-content-start">
            <Checkbox
              sizeClass="small"
              value={filterData.priorSixMonths || false}
              label="Show All Requests ( Prior 6 Months )"
              onChange={() => {
                setFilterData({
                  ...filterData,
                  priorSixMonths: !filterData.priorSixMonths,
                });
              }}
            />
          </div>
          <div className="user-request-table">
            <MyPnPDataGrid
              columns={columns(
                userRequestData[0]?.status === "Approved" ||
                  userRequestData[0]?.status === "Rejected"
                  ? true
                  : false
              )}
              rowData={userRequestData}
              isPagination={true}
              paginationConfig={{
                pageNumber: currentPage,
                pageSize: 10,
                totalRecords: userRequestData ? userRequestData.length : 1,
                onPageClick: onPageNumClick,
              }}
            />
          </div>
        </div>
      </div>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onConfirm={() => handleRejectRequest}
          actionButton="Confirm"
          varient={modalVarientEnum.DEFAULT}
          actionButtonRequired={false}
        >
          <div className="d-flex-content-center">
            <div className="text-center mt-20">
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="44"
                  height="44"
                  viewBox="0 0 44 44"
                  fill="none"
                >
                  <path
                    d="M21.9987 1.83334C10.8154 1.83334 1.83203 10.8167 1.83203 22C1.83203 33.1833 10.8154 42.1667 21.9987 42.1667C33.182 42.1667 42.1654 33.1833 42.1654 22C42.1654 10.8167 33.182 1.83334 21.9987 1.83334ZM23.832 22V33H20.1654V22H18.332V18.3333H21.9987C23.0987 18.3333 23.832 19.0667 23.832 20.1667V22ZM21.082 14.6667C19.6154 14.6667 18.332 13.3833 18.332 11.9167C18.332 10.45 19.6154 9.16668 21.082 9.16668C22.5487 9.16668 23.832 10.45 23.832 11.9167C23.832 13.3833 22.5487 14.6667 21.082 14.6667Z"
                    fill="#FF0011"
                  />
                </svg>
              </div>
              <div className="reject-message mt-20">
                {general_text_data.are_you_sure_want_to_reject}
                <br />
                {general_text_data.user_new_application_request}
              </div>
            </div>
          </div>
          <div className="modal-action-btn ">
            <MUIButton
              label={general_text_data.cancel}
              onClickButton={() => setIsModalOpen(false)}
              size={32}
              variant="secondary"
            />
            <MUIButton
              label={general_text_data.yes_reject}
              onClickButton={handleRejectRequest}
              size={32}
              variant="primary"
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default NewUserRequest;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AppGeneralTextContext } from "./app_context/app_context";
import { useNavigate } from "react-router-dom";
import {
  Checkbox,
} from "@abb/abb-common-ux-react";
import { MyPnPDataGrid } from "pnp-components-library";
import moment from "moment";
import {
  getAllApplicationByCategory,
  getAllApplications,
  getAllStatuses,
  getUserRequests,
  rejectApplication,
} from "./services/myPnpServices";
import { AllApplications } from "./role_definition";
import { useNotification } from "./app_context/NotificationContext";
import { buildNotification, buildPaginationData } from "./utils/utils";
import Modal, { modalVarientEnum } from "./components/common/GenericModal";
import { getUserDataFromToken } from "./utils/buildnotify";
import {
  MUIButton,
  MUISelect,
  MUITextField,
  Pagination,
} from "@abb/abb-arcadia-ui-react";

const NewUserRequest = () => {
  const appGeneralTextData = React.useContext(AppGeneralTextContext);
  const { general_text_data }: any = appGeneralTextData;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  const [filterData, setFilterData] = React.useState<any>({
    firstname: "",
    lastname: "",
    email: "",
    applicationName: "",
    category: "",
    status: {
      value: 1,
      label: "Pending",
    },
    priorSixMonths: false,
  });

  const [allStatus, setAllStatus] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [userRequestData, setUserRequestData] = useState<any>([]);
  const [allCategory, setAllCategory] = useState<AllApplications[]>();
  const [applicationList, setApplicationList] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>();
  const [permitted, setPermitted] = useState(true);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const { showNotification } = useNotification();
  const [selectedRow, setSelectedRow] = useState<any>();
  const [pageSize, setPageSize] = useState<any>(10);
  const user_data = getUserDataFromToken() || null;
  const [theme, setTheme] = useState<string>("");
  const storedTheme = localStorage.getItem("userTheme");

  useEffect(() => {
    getStatuses();
    getPgageData();
    callGetAllApplications();
  }, []);

  useEffect(() => {
    const { realm_access } = user_data || {};
    if (!realm_access?.roles?.includes("SuperAdmin")) {
      setPermitted(false);
    }
  }, []);

  useEffect(() => {
    if (!permitted) {
      setShowPermissionModal(true);
    }
  }, [permitted]);

  useEffect(() => {
    getPgageData();
  }, [filterData.priorSixMonths]);

  const callGetAllApplications = async () => {
    try {
      const resp = await getAllApplications();

      if (resp.length > 0) {
        const modifiedRes = resp?.map((item: any) => {
          return {
            value: item?.client_id,
            label: item?.client_Name,
          };
        });
        let applications: any[] = resp;
        setAllCategory(applications);
        setSelectedCategory(modifiedRes[1]);
        fetchApplications(modifiedRes[1]?.value);
      }
    } catch (err: any) {
      if (err.response && err.response.status === 403) {
        setPermitted(false);
        setShowPermissionModal(true);
      } else {
        console.log(err);
      }
    }
  };

  const fetchApplications = async (value: any) => {
    try {
      const result = await getAllApplicationByCategory(2);
      if (result?.length > 0) {
        setApplicationList(result);
      }
    } catch (err) {
      showNotification([
        buildNotification("Failed to get an application", "alarm"),
      ]);
    }
  };

  const getStatuses = async () => {
    try {
      const response = await getAllStatuses();
      const modifiedRes = response?.map((item: any) => {
        return {
          value: item.statusId,
          label: item.statusName,
        };
      });
      setAllStatus(modifiedRes);
      setFilterData({ ...filterData, status: modifiedRes[0] });
    } catch (err: any) {
      if (err.response && err.response.status === 403) {
        setPermitted(false);
        setShowPermissionModal(true);
      } else {
        console.log(err);
      }
    }
  };
  const getPgageData = async () => {
    const payload = {
      userName: filterData?.email || "",
      firstName: filterData?.firstname || "",
      lastName: filterData?.lastname || "",
      appRegId: filterData?.applicationName || null,
      statusId:
        filterData?.status === undefined ? null : filterData?.status?.value,
      category: filterData?.category || "",
      priorSixMonths: filterData?.priorSixMonths,
    };

    try {
      const response = await getUserRequests(payload);
      if (response?.data) {
        setUserRequestData(response?.data);
      }
    } catch (err: any) {
      if (err.response && err.response.status === 403) {
        setPermitted(false);
        setShowPermissionModal(true);
      } else {
        console.log(err);
      }
    }
  };

  const ActionLink = (value: any) => {
    const rowData = value.data;
    if (rowData.statusId === 1)
      return (
        <div className="action-link">
          <span
            onClick={() =>
              navigate("/platform_administration/roles_user_management/user_mapping/assignUserRole", {
                state: {
                  email: rowData?.username,
                  applicationName: rowData?.applicationName,
                  id: rowData?.id,
                  clientId: selectedCategory?.value,
                },
              })
            }
          >
            {general_text_data.approve}
          </span>
          |
          <span
            onClick={() => {
              setSelectedRow(rowData);
              setIsModalOpen(true);
            }}
          >
            {general_text_data.reject}
          </span>
        </div>
      );
  };
  const onPageNumClick = (evt: any, pageNumber: any) => {
    setCurrentPage(pageNumber);
  };

  const handleDates = useCallback((list: any, prop: any) => {
    return (
      list?.map((item: any) => {
        const obj = { ...item };
        obj.key = item?.workOrderId;
        prop?.forEach((field: any) => {
          obj[field] = moment(obj[field]).format("YYYY/MM/DD");
        });
        return obj;
      }) || []
    );
  }, []);

  const buildTableData = useMemo(() => {
    let currentUsersData = buildPaginationData(
      currentPage + 1,
      pageSize,
      userRequestData
    );
    return handleDates(currentUsersData ?? [], ["createdDate"]);
  }, [currentPage, userRequestData, pageSize]);

  const handleRejectRequest = async () => {
    const payload = {
      userRequestId: selectedRow.id,
      approverName: user_data?.name,
      isRejected: true,
    };
    try {
      const result = await rejectApplication(payload);
      if (result) {
        showNotification([buildNotification(result.data, "success")]);
        getPgageData();
        setIsModalOpen(false);
      }
    } catch (err) {
      showNotification([
        buildNotification("Failed to reject application request", "alarm"),
      ]);
    }
  };
  const columns = (isActionRequired: boolean) => {
    return [
      {
        title: "User Name",
        field: "firstName",
        flex: 1,
      },
      {
        title: "User Last Name",
        field: "lastName",
        flex: 1,
      },

      {
        title: "Email",
        field: "username",
        flex: 1,
      },
      {
        field: "applicationName",
        flex: 1,
      },
      {
        field: "status",
        flex: 1,
      },
      {
        field: "createdDate",
        flex: 1,
      },

      {
        field: "comment",
        flex: 1,
      },

      ...(isActionRequired
        ? []
        : [
            {
              field: "Process",
              title: "Approve / Reject",
              flex: 1,
              cellRenderer: ActionLink,
            },
          ]),
    ];
  };

  const handleRowsPerPage = (evt: any) => {
    const { value } = evt?.target || {};
    setPageSize(value);
  };

  return (
    <div className="new-user-request-container">
      <div className="role-deinition-screen">
        {!permitted && showPermissionModal && (
          <div className="modal-overlay">
            <div className="modal red-border">
              <div className="modal-content">
                <button
                  className="modal-close"
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  &times;
                </button>
                <p>
                  You don’t have the required permissions to access Platform
                  Administration Application. Please contact the administrator.
                </p>
              </div>
            </div>
          </div>
        )}
        {permitted === true && (
          <>
            <h1>{general_text_data.user_requests}</h1>
            <div className="filter-section">
              <div className="filter-button">
                <div className="filter-label">
                  {general_text_data.filter_by} :
                </div>
              </div>
              <div className="grid-container gap-20 mt-20">
                <div className="col-3">
                  <label className="filter-label">
                    {general_text_data.first_name}
                  </label>

                  <MUITextField
                    onChange={(val: string) =>
                      setFilterData({ ...filterData, firstname: val })
                    }
                    value={filterData.firstname || ""}
                    onKeyDown={() => {}}
                    placeholder={"First Name"}
                    minWidth={240}
                    size={32}
                  />
                </div>
                <div className="col-3">
                  <label className="filter-label">
                    {general_text_data.last_name}
                  </label>

                  <MUITextField
                    onChange={(val: string) =>
                      setFilterData({ ...filterData, lastname: val })
                    }
                    value={filterData.lastname || ""}
                    onKeyDown={() => {}}
                    placeholder={"Last Name"}
                    minWidth={240}
                    size={32}
                  />
                </div>
                <div className="col-3">
                  <label className="filter-label">
                    {general_text_data.email} :
                  </label>

                  <MUITextField
                    onChange={(val: string) =>
                      setFilterData({ ...filterData, email: val })
                    }
                    value={filterData.email || ""}
                    onKeyDown={() => {}}
                    placeholder={"E-mail"}
                    minWidth={240}
                    size={32}
                  />
                </div>
              </div>
              <div className="grid-container gap-20 mt-20">
                <div className="col-3">
                  <div className="filter-fields">
                    <label className="filter-label">
                      {general_text_data.category} :
                    </label>
                    <MUISelect
                      dropdownLOV={
                        allCategory?.map((e: any) => {
                          return {
                            id: e.client_id,
                            name: e.client_Name,
                          };
                        }) || []
                      }
                      onChange={(val: string[]) =>
                        setFilterData({ ...filterData, category: val[0] })
                      }
                      selectedItem={selectedCategory}
                      size={32}
                      minWidth={280}
                      multiSelect={false}
                    />
                  </div>
                </div>

                <div className="col-3">
                  <label className="filter-label">
                    {general_text_data.application_name} :
                  </label>

                  <MUISelect
                    dropdownLOV={
                      applicationList?.length > 0
                        ? applicationList?.map((e: any) => {
                            return { id: e.appRegId, name: e.applicationName };
                          })
                        : []
                    }
                    onChange={(val: string[]) =>
                      setFilterData({ ...filterData, applicationName: val[0] })
                    }
                    selectedItem={filterData.applicationName || null}
                    size={32}
                    minWidth={280}
                    multiSelect={false}
                  />
                </div>
                <div className="col-3">
                  <label className="filter-label">
                    {general_text_data.status} :
                  </label>

                  <MUISelect
                    dropdownLOV={
                      allStatus?.length > 0
                        ? allStatus?.map((e: any) => {
                            return { id: e.label, name: e.label };
                          })
                        : []
                    }
                    onChange={(val: string[]) => {
                      let status: any = allStatus?.find(
                        (item: any) => item?.label === val[0]
                      );
                      setFilterData({ ...filterData, status });
                    }}
                    selectedItem={[
                      filterData.status ? filterData.status?.label : "",
                    ]}
                    size={32}
                    minWidth={280}
                    multiSelect={false}
                  />
                </div>
                <div className="col-3">
                  <div className="apply-button">
                    <MUIButton
                      label={general_text_data.apply}
                      onClickButton={() => getPgageData()}
                      size={32}
                      variant="primary"
                      className="mt-20 "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-20">
              <div className="d-flex-justify-content-start">
                <Checkbox
                  sizeClass="small"
                  value={filterData.priorSixMonths || false}
                  label="Show All Requests ( Prior 6 Months )"
                  onChange={() => {
                    setFilterData({
                      ...filterData,
                      status: filterData?.status || null,
                      priorSixMonths: !filterData.priorSixMonths,
                    });
                  }}
                />
              </div>
              <div className="user-request-table">
                <MyPnPDataGrid
                  columns={columns(
                    userRequestData[0]?.status === "Approved" ||
                      userRequestData[0]?.status === "Rejected"
                      ? true
                      : false
                  )}
                  rowData={buildTableData || []}
                  theme={storedTheme}
                />
                <Pagination
                  count={userRequestData.length || 0}
                  handleChangePage={onPageNumClick}
                  handleChangeRowsPerPage={handleRowsPerPage}
                  page={currentPage}
                  rowsPerPage={pageSize}
                  showPrevNext={false}
                  showNumberButtons={false}
                />
              </div>
            </div>
          </>
        )}
      </div>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onConfirm={() => handleRejectRequest}
          actionButton="Confirm"
          varient={modalVarientEnum.DEFAULT}
          actionButtonRequired={false}
        >
          <div className="d-flex-content-center">
            <div className="text-center mt-20">
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="44"
                  height="44"
                  viewBox="0 0 44 44"
                  fill="none"
                >
                  <path
                    d="M21.9987 1.83334C10.8154 1.83334 1.83203 10.8167 1.83203 22C1.83203 33.1833 10.8154 42.1667 21.9987 42.1667C33.182 42.1667 42.1654 33.1833 42.1654 22C42.1654 10.8167 33.182 1.83334 21.9987 1.83334ZM23.832 22V33H20.1654V22H18.332V18.3333H21.9987C23.0987 18.3333 23.832 19.0667 23.832 20.1667V22ZM21.082 14.6667C19.6154 14.6667 18.332 13.3833 18.332 11.9167C18.332 10.45 19.6154 9.16668 21.082 9.16668C22.5487 9.16668 23.832 10.45 23.832 11.9167C23.832 13.3833 22.5487 14.6667 21.082 14.6667Z"
                    fill="#FF0011"
                  />
                </svg>
              </div>
              <div className="reject-message mt-20">
                {general_text_data.are_you_sure_want_to_reject}
                <br />
                {general_text_data.user_new_application_request}
              </div>
            </div>
          </div>
          <div className="modal-action-btn ">
            <MUIButton
              label={general_text_data.cancel}
              onClickButton={() => setIsModalOpen(false)}
              size={32}
              variant="secondary"
            />
            <MUIButton
              label={general_text_data.yes_reject}
              onClickButton={handleRejectRequest}
              size={32}
              variant="primary"
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default NewUserRequest;

import React from "react";
import {
  AppGeneralTextContext,
} from "./app_context/app_context";

import { Link } from "react-router-dom";

const InvalidPage = () => {
  const appGeneralTextData = React.useContext(AppGeneralTextContext);
  const { general_text_data }: any = appGeneralTextData;
  return (
    <div className="services-container">
      <div className="intro-header">
        <h1>Looking for something?</h1>
      </div>
      <div className="intro-content"> {general_text_data.not_valid}</div>
      <div className="intro-content">
        {" "}
        Go to{" "}
        <Link to={"/home"} reloadDocument={true}>
          Home
        </Link>{" "}
        Page
      </div>
    </div>
  );
};

export default InvalidPage;

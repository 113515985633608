import React from "react";
import { ImagesContext } from "./app_context/app_context";

const BannerImage = () => {
  const imagesData = React.useContext(ImagesContext);
  const { images }: any = imagesData;

  return (
    <div className="bannerimg-container">
      <section className="carousel" aria-label="Gallery">
        <ol className="carousel__viewport">
          <li id="carousel__slide1" className="carousel__slide">
            <img src={images.mypnp_banner} alt="" className="carousel__image" />
            <div className="carousel__snapper"></div>
          </li>
          <li id="carousel__slide2" className="carousel__slide">
            <img src={images.mypnp_banner} alt="" className="carousel__image" />
            <div className="carousel__snapper"></div>
          </li>
          <li id="carousel__slide3" className="carousel__slide">
            <img src={images.mypnp_banner} alt="" className="carousel__image" />
            <div className="carousel__snapper"></div>
          </li>
        </ol>
        <aside className="carousel__navigation">
          <ol className="carousel__navigation-list">
            <li className="carousel__navigation-item">
              <a
                href="#carousel__slide1"
                className="carousel__navigation-button"
                aria-current="true"
              >
                Go to slide 1
              </a>
            </li>
            <li className="carousel__navigation-item">
              <a
                href="#carousel__slide2"
                className="carousel__navigation-button"
              >
                Go to slide 2
              </a>
            </li>
            <li className="carousel__navigation-item">
              <a
                href="#carousel__slide3"
                className="carousel__navigation-button"
              >
                Go to slide 3
              </a>
            </li>
          </ol>
        </aside>
      </section>
    </div>
  );
};

export default BannerImage;

import React from "react";
import { Field } from 'formik';

const UseAbbField = ({ value, label, name, placeholder, type, onChange, component, readOnly, disabled }: any) => (
  <Field
    type={type}
    value={value}
    name={name}
    className="form-control"
    placeholder={placeholder}
    onChange={onChange}
    component={component}
    readOnly={readOnly}
    disabled={disabled}
  />
);

export default UseAbbField;

import React, { useEffect } from "react";
import { assignServiceApp, hostURL } from "./services/myPnpServices";
import { useUserContext } from "./app_context/UserContext";
import { addRefreshToken } from "./utils/apiServices";

const ServiceProAccessRequest: React.FC = () => {
  const { userData } = useUserContext();

  useEffect(() => {
    callAssignServicePro();
  }, []);

  const callAssignServicePro = async () => {
    try {
      const resp = await assignServiceApp();
      console.log("resp", resp === true);
      if (resp === true) {
        callRefreshTokenApi();
      }
    } catch {}
  };

  const callRefreshTokenApi = async () => {
    try {
      const response =
        (await userData?.callRefreshToken()) && userData?.callRefreshToken();

      let service_pro_url = hostURL + "/servicepro";

      setTimeout(() => {
        window.location.href = service_pro_url;
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="role-deinition-screen">
      <h1>Redirecting...</h1>
      <p>You will be redirected to Service Pro shortly.</p>
    </div>
  );
};

export default ServiceProAccessRequest;

import React from "react";
import { AppGeneralTextContext } from "./app_context/app_context";

const WinderPerformance = () => {
  const appGeneralTextData = React.useContext(AppGeneralTextContext);
  const { general_text_data }: any = appGeneralTextData;

  return (
    <div className="services-container">
      <div className="intro-header">
        <h1>Winder Performance</h1>
      </div>
      <div className="intro-content"> {general_text_data.coming_soon}</div>
    </div>
  );
};

export default WinderPerformance;

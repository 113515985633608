import React, { useEffect, useState } from "react";
import { AppGeneralTextContext } from "./app_context/app_context";
import * as ABB from "@abb/abb-common-ux-react";
import { buildNotification } from "./utils/utils";
import { useNotification } from "./app_context/NotificationContext";
import {
  approveApplication,
  AssignedRoleToUser,
  getAllowedRoles,
  getUserDetailsWithAppltnRole,
  rejectApplication,
} from "./services/myPnpServices";
import { useLocation, useNavigate } from "react-router-dom";
import Modal, { modalVarientEnum } from "./components/common/GenericModal";
import { getUserDataFromToken } from "./utils/buildnotify";
import { MUIButton } from "@abb/abb-arcadia-ui-react";

export interface AllRoles {
  clientRole: boolean;
  composite: boolean;
  description: string;
  id: string;
  name: string;
  usersInRole: any;
  usersInRoleCount: number;
  container_id: string;
}

export interface AllApplications {
  client_Name: string;
  client_id: string;
  application: string;
}
export interface roleType {
  id: string;
  name: string;
  description: string;
  composite: boolean;
  clientRole: boolean;
  containerId: string;
}

const AssignUserRole = () => {
  const appGeneralTextData = React.useContext(AppGeneralTextContext);
  const { general_text_data }: any = appGeneralTextData;
  const [allRoles, setAllRoles] = useState<AllRoles[]>([]);
  const { showNotification } = useNotification();
  const [selectedRoles, setSelectedRoles] = useState<any[]>([]);
  const [taggedRoles, setTaggedRoles] = useState<any[]>([]);
  const [requestUserData, setRequestUserData] = useState<any>(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { email, id, clientId } = state;
  const user_data = getUserDataFromToken() || null;

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    callGetUserRoles();
    getUserDetails();
  }, []);
  const getUserDetails = async () => {
    try {
      let payload = {
        start: 0,
        max: 10,
        search: email,
      };
      let result = await getUserDetailsWithAppltnRole(payload);
      if (result) {
        setRequestUserData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleRoleSelect = (value: any) => {
    const isRoleSelected = selectedRoles.some(
      (role: any) => role.value === value.value
    );
    const isRoleTagged = taggedRoles.some(
      (role: any) => role.value === value.value
    );

    if (!isRoleSelected && !isRoleTagged) {
      let role = {
        value: value.value,
        label: value.label,
      };
      setSelectedRoles([...selectedRoles, role]);
      setTaggedRoles([...taggedRoles, role]);
    }
  };

  const handleRemoveRole = (role: any) => {
    setTaggedRoles(taggedRoles.filter((r: any) => r.value !== role.value));
    setSelectedRoles(selectedRoles.filter((r: any) => r.value !== role.value));
  };

  const handleSave = async () => {
    const data = {
      userRequestId: id,
      approverName: user_data?.name,
      isRejected: false,
    };
    try {
      const result = await approveApplication(data);
      if (result) {
        showNotification([buildNotification(result.data, "success")]);
        const selectedRolesData = taggedRoles
          .map((role: any) =>
            allRoles.find((allRole: any) => allRole.roleId === role.value)
          )
          .filter(Boolean);
        let payload = selectedRolesData.map((role: any) => ({
          id: role.roleId,
          name: role.roleName,
          description: role.description || "",
          composite: role.composite || false,
          clientRole: role.clientRole || true,
          containerId: role.containerId,
        }));
        assignRoles(payload);
      }
    } catch (err) {
      showNotification([
        buildNotification("Failed to reject application", "alarm"),
      ]);
    }
  };
  const handleRejectRequest = async () => {
    const payload = {
      userRequestId: id,
      approverName: user_data?.name,
      isRejected: true,
    };
    try {
      const result = await rejectApplication(payload);
      if (result) {
        showNotification([buildNotification(result.data, "success")]);
        navigate("/roles_user_management/user_requests");
      }
    } catch (err) {
      showNotification([
        buildNotification("Failed to reject application", "alarm"),
      ]);
    }
  };
  const assignRoles = async (roles: roleType[]) => {
    try {
      let response = await AssignedRoleToUser(
        clientId,
        requestUserData[0].id,
        requestUserData[0].username,
        roles
      );
      if (response) {
        showNotification([
          buildNotification(
            "New role is assigned successfully to the selected user!",
            "success"
          ),
        ]);
        navigate("/roles_user_management/user_requests");
      }
    } catch (err: any) {
      showNotification([
        buildNotification("Failed to assign role to user!", "alarm"),
      ]);
    }
  };

  const callGetUserRoles = async () => {
    let payload = {
      requestId: id,
      applicationtenantId: clientId,
    };

    try {
      const response = await getAllowedRoles(payload);
      if (response.data.length > 0) {
        const modifiedRoles = response.data.map((role: any) => {
          const splitRoleName = role.roleName.split(":");
          const displayName =
            splitRoleName.length > 1 ? splitRoleName[1].trim() : role.roleName;

          return {
            ...role,
            displayName,
          };
        });

        setAllRoles(modifiedRoles);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="assign-role-container ">
        <div>
          <h1>{general_text_data.invite_new_user}</h1>
        </div>

        <div className="assign-role-content-container mt-20">
          <div className="assign-role-title-container">
            <h3 className="assign-role-title">
              {general_text_data.assign_role}
            </h3>
          </div>
          <div className="input-container">
            <div className="lhs-input-column-one gap-40">
              <div>
                <label htmlFor="user">{general_text_data.user}</label>
                <br />

                <ABB.Input
                  type="normal"
                  dataType="text"
                  placeholder="First Name"
                  value={email || ""}
                  className="input-width-90"
                  disabled
                />
              </div>
              <div>
                <label htmlFor="user">{general_text_data.category} :</label>
                <br />

                <ABB.Input
                  type="normal"
                  dataType="text"
                  placeholder="select category"
                  value="Extensions"
                  className="input-width-90"
                  disabled
                />
              </div>
              <div className="role user-new-role">
                <label htmlFor="role">{general_text_data.role}</label>
                <br />

                <ABB.Dropdown
                  onChange={(val) => handleRoleSelect(val[0])}
                  value={selectedRoles}
                  multiselect={false}
                  placeholder="Select Role"
                >
                  {allRoles?.map((e: any, id: any) => (
                    <ABB.DropdownOption
                      key={e.roleId}
                      label={e.displayName}
                      value={e.roleId}
                    />
                  ))}
                </ABB.Dropdown>
              </div>
            </div>
            <div className="rhs-text-area-container-and-btn-group">
              {taggedRoles && taggedRoles.length > 0 && (
                <div className="togged-user-new-roles">
                  <h3 className="togged-role-text">
                    {general_text_data.togged_roles}
                  </h3>
                  <div>
                    <div className="card-container">
                      {taggedRoles?.map((role: any, index: any) => (
                        <div key={index} className="role-card">
                          <span>{role.label}</span>
                          <button onClick={() => handleRemoveRole(role)}>
                            x
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="assign-action-buttons">
            <MUIButton
              label={general_text_data.cancel}
              onClickButton={() =>
                navigate("/roles_user_management/user_requests")
              }
              size={32}
              variant="secondary"
            />
            <MUIButton
              label={general_text_data.approve}
              onClickButton={handleSave}
              size={32}
              variant="primary"
              disabled={selectedRoles.length === 0 || selectedRoles === null}
            />
            <MUIButton
              label={general_text_data.reject}
              onClickButton={() => setIsModalOpen(true)}
              size={32}
              variant="primary"
              disabled={selectedRoles.length === 0 || selectedRoles === null}
            />
          </div>
        </div>
        {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onConfirm={() => handleRejectRequest}
            actionButton="Confirm"
            varient={modalVarientEnum.DEFAULT}
            actionButtonRequired={false}
          >
            <div className="d-flex-content-center">
              <div className="text-center mt-20">
                <div className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="44"
                    height="44"
                    viewBox="0 0 44 44"
                    fill="none"
                  >
                    <path
                      d="M21.9987 1.83334C10.8154 1.83334 1.83203 10.8167 1.83203 22C1.83203 33.1833 10.8154 42.1667 21.9987 42.1667C33.182 42.1667 42.1654 33.1833 42.1654 22C42.1654 10.8167 33.182 1.83334 21.9987 1.83334ZM23.832 22V33H20.1654V22H18.332V18.3333H21.9987C23.0987 18.3333 23.832 19.0667 23.832 20.1667V22ZM21.082 14.6667C19.6154 14.6667 18.332 13.3833 18.332 11.9167C18.332 10.45 19.6154 9.16668 21.082 9.16668C22.5487 9.16668 23.832 10.45 23.832 11.9167C23.832 13.3833 22.5487 14.6667 21.082 14.6667Z"
                      fill="#FF0011"
                    />
                  </svg>
                </div>
                <div className="reject-message mt-20">
                  {general_text_data.are_you_sure_want_to_reject}
                  <br />
                  {general_text_data.user_new_application_request}
                </div>
              </div>
            </div>
            <div className="modal-action-btn ">
              <MUIButton
                label={general_text_data.cancel}
                onClickButton={() => setIsModalOpen(false)}
                size={32}
                variant="secondary"
              />
              <MUIButton
                label={general_text_data.yes_reject}
                onClickButton={handleRejectRequest}
                size={32}
                variant="primary"
              />
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};
export default AssignUserRole;

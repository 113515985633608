import { Spinner } from "@abb/abb-common-ux-react";

interface LoaderProps {
  showOverlay?: boolean;
}
const Loader = (props: LoaderProps) => {
  const { showOverlay } = props;
  return (
    <>
      {showOverlay ? (
        <div className="loader-overlay">
          <Spinner color={"blue"} sizeClass={"medium"} />
        </div>
      ) : (
        <Spinner color={"blue"} sizeClass={"medium"} />
      )}
    </>
  );
};

export default Loader;

import React, { useEffect } from "react";
import { hostURL } from "./services/myPnpServices";

const ServicePro: React.FC = () => {
  useEffect(() => {
    let service_pro_url = hostURL + "/servicepro";
    setTimeout(() => {
      window.location.href = service_pro_url;
    }, 1000);
  }, []);

  return (
    <div className="role-deinition-screen">
      <h1>Redirecting...</h1>
      <p>You will be redirected to Service Pro shortly.</p>
    </div>
  );
};

export default ServicePro;

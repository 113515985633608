// NotificationContext.js
import React, { createContext, useState, useContext, useMemo } from "react";

const NotificationContext = createContext<any>(null);

export const NotificationProvider = (props: any) => {
  const { children } = props;
  const [notification, setNotification] = useState<any>();

  const showNotification = (message: any) => {
    setNotification(message);
    setTimeout(() => {
      setNotification([]);
    }, 3000);
  };

  const contextValue = useMemo(
    () => ({ showNotification, notification }),
    [showNotification, notification]
  );
  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);

import React, { useState } from "react";
import { AppGeneralTextContext } from "./app_context/app_context";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useNotification } from "./app_context/NotificationContext";
import { buildNotification, isInvalid } from "./utils/utils";
import {
  addNewRole,
  getAllApplicationByCategory,
} from "./services/myPnpServices";
import { Button, Dropdown, DropdownOption } from "@abb/abb-common-ux-react";
import { MUIButton } from "@abb/abb-arcadia-ui-react";

interface newRoleProps {
  applications: any;
}
const NewRole = (props: newRoleProps) => {
  let { applications } = props;
  const allCategory = applications;
  const [selectedCategory, setSelectedCategory] = useState<any>([]);
  const [apllicationList, setApplicationList] = useState<any>([]);
  const [selectedApplication, setSelectedApplication] = useState<any>([]);
  const appGeneralTextData = React.useContext(AppGeneralTextContext);
  const { general_text_data }: any = appGeneralTextData;
  const navigate = useNavigate();
  const { showNotification } = useNotification();

  const handleCancelpage = () => {
    navigate(0);
  };

  const NewRoleFormSchema = Yup.object().shape({
    rolename: Yup.string()
      .required("This field is required")
      .min(3, general_text_data.too_short),
    description: Yup.string()
      .required("This field is required")
      .min(2, general_text_data.too_short),
    application: Yup.string().required("This field is required"),
  });

  const handleCategoryValue = (value: any) => {
    setSelectedCategory(value[0]);
    setSelectedApplication([]);
    fetchApplications(value[0].value);
  };
  const handleApplicationValue = (value: any) => {
    setSelectedApplication(value[0]);
  };

  const fetchApplications = async (value: any) => {
    try {
      const result = await getAllApplicationByCategory(value);
      if (result.length > 0) {
        setApplicationList(result);
      }
    } catch (err) {
      showNotification([
        buildNotification("Failed to get an application", "alarm"),
      ]);
    }
  };

  const AddNewRole = async (values: any) => {
    const client = allCategory.find(
      (client: any) => client.applicationCategoryId === selectedCategory.value
    );
    let data = {
      name: values.rolename,
      description: values.description,
      composite: true,
      clientRole: true,
      containerId: client.client_id,
      categoryName: selectedCategory.label,
      applicationId: selectedApplication.value,
    };
    try {
      const response = await addNewRole(data);
      if (response) {
        showNotification([
          buildNotification("New Role is Created Successfully!", "success"),
        ]);
      }
      navigate(0);
    } catch (error: any) {
      if (error.response.status === 403) {
        showNotification([buildNotification(error.response.data, "alarm")]);
      } else if (
        error.response.status === 400 &&
        error.response.data.includes("already exists")
      ) {
        showNotification([
          buildNotification(
            `Role with name ${values.rolename} already exist`,
            "alarm"
          ),
        ]);
      } else {
        showNotification([
          buildNotification("Failed to create new role", "alarm"),
        ]);
      }
    }
  };

  return (
    <div>
      <h1>{general_text_data.new_role}</h1>
      <Formik
        initialValues={{
          rolename: "",
          description: "",
          application: selectedCategory,
        }}
        validationSchema={NewRoleFormSchema}
        onSubmit={AddNewRole}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => (
          <Form>
            <div className="new-role-container">
              <div className="new-role-title-container">
                <h3 className="new-role-title">Add New Role</h3>
              </div>
              <div className="grid-container">
                <div className="col-4">
                  <div className="new-role-content-container">
                    <div className="new-role-content-section">
                      <div className="new-role-label">
                        Add New Role Name<span className="asterisk"> *</span>
                      </div>
                      <input
                        className="new-role-input"
                        type="text"
                        name="rolename"
                        placeholder="Add new role"
                        value={values.rolename}
                        onChange={handleChange}
                      />
                      {errors.rolename && touched.rolename ? (
                        <div className="formik-error">{errors.rolename}</div>
                      ) : null}
                    </div>
                    <div className="new-role-content-section">
                      <div className="new-role-label">
                        Description<span className="asterisk">*</span>
                      </div>
                      <input
                        className="new-role-input"
                        type="text"
                        name="description"
                        placeholder="Description"
                        value={values.description}
                        onChange={handleChange}
                      />
                      {errors.description && touched.description ? (
                        <div className="formik-error">{errors.description}</div>
                      ) : null}
                    </div>
                    <div className="new-role-content-section">
                      <div className="new-role-label">
                        Select Category <span className="asterisk">*</span>
                      </div>

                      <Dropdown
                        onChange={(val) => handleCategoryValue(val)}
                        value={selectedCategory}
                        multiselect={false}
                        placeholder="Select Application"
                      >
                        {allCategory?.map((e: any) => (
                          <DropdownOption
                            key={e.applicationCategoryId}
                            label={e.client_Name}
                            value={e.applicationCategoryId}
                          />
                        ))}
                      </Dropdown>
                    </div>
                    <div className="new-role-content-section">
                      <div className="new-role-label">
                        Select Application <span className="asterisk">*</span>
                      </div>

                      <Dropdown
                        onChange={(val) => handleApplicationValue(val)}
                        value={selectedApplication}
                        multiselect={false}
                        placeholder="Select Application"
                      >
                        {apllicationList?.length > 0
                          ? apllicationList?.map((e: any) => (
                              <DropdownOption
                                key={e.appRegId}
                                label={e.applicationName}
                                value={e.appRegId}
                              />
                            ))
                          : null}
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
              <div className="new-role-definition-input-group-button">
                <MUIButton
                  label={general_text_data.cancel}
                  onClickButton={handleCancelpage}
                  size={32}
                  variant="secondary"
                />
                <MUIButton
                  label={general_text_data.save}
                  onClickButton={() => AddNewRole(values)}
                  size={32}
                  variant="primary"
                  disabled={
                    isInvalid(values.description) ||
                    isInvalid(values.rolename) ||
                    selectedCategory.label === undefined ||
                    selectedCategory.label === "" ||
                    selectedApplication.label === undefined ||
                    selectedApplication.label === ""
                  }
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewRole;

import { Button, Icon } from "@abb/abb-common-ux-react";
import React, { useState } from "react";
import { AppGeneralTextContext } from "../../app_context/app_context";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: any;
  onConfirm?: () => void;
  actionButton?: string;
  actionButtonRequired?: boolean;
  varient?: modalVarientEnum;
  onCloseOnClickOutside?: boolean;
}
export enum modalVarientEnum {
  DANGER = "red-border",
  DEFAULT = "border-none",
  WARN = "orange-border"
}

const Modal = (props: ModalProps) => {
  const {
    isOpen,
    onClose,
    children,
    onConfirm,
    actionButton,
    actionButtonRequired = true,
    varient = modalVarientEnum.DEFAULT,
    onCloseOnClickOutside = true,
  } = props;
  const [modalOpen, setModalOpen] = useState(isOpen);
  const appGeneralTextData = React.useContext(AppGeneralTextContext);
  const { general_text_data }: any = appGeneralTextData;
  // Close the modal
  const closeModal = () => {
    setModalOpen(false);
    onClose();
  };
  const closeModalOnOutsideClick = (event: any) => {
    if (onCloseOnClickOutside && event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div>
      {modalOpen && (
        <div className="modal-overlay" onClick={closeModalOnOutsideClick}>
          <div className={`modal ${varient}`}>
            <Icon
              name="abb/close"
              className="modal-close-icon"
              onClick={closeModal}
            />
            <div className="modal-content">{children}</div>
            {actionButtonRequired && (
              <div className="modal-action-btn">
                <Button
                  sizeClass="small"
                  type="primary-blue"
                  shape={"rounded"}
                  text={actionButton}
                  onClick={onConfirm}
                />
                <Button
                  sizeClass="small"
                  type="discreet-blue"
                  shape={"rounded"}
                  text={general_text_data.cancel}
                  onClick={closeModal}
                />
              </div>
            )}
          </div>
        </div>
      )}
     
    </div>
  );
};

export default Modal;

import React, { useState, useEffect } from "react";
import * as ABB from "@abb/abb-common-ux-react";
import { AppGeneralTextContext } from "./app_context/app_context";
import { Link, useNavigate } from "react-router-dom";
import { Dialog } from "@abb/abb-common-ux-react";
import UseAbbField from "../src/hooks/UseAbbField";
import { Formik, Form } from "formik";
import {
  createAppltnConfig,
  deleteAppltnConfig,
  getAllApplicationType,
  getAllApplications,
  getAllPillarData,
  updateAppltnConfig,
  getAllApplicationConfigurations,
  getAllRoles,
} from "./services/myPnpServices";
import { buildNotification, isInvalid } from "./utils/utils";
import { useNotification } from "./app_context/NotificationContext";
import { AllApplications } from "./assignrole";
import { MUIButton } from "@abb/abb-arcadia-ui-react";

interface Dict {
  [key: string]: any;
}

const AddApplication = () => {
  const initVals = {
    applicationName: "",
    applicationId: "",
    applicationTypeId: "",
    applicationTypeName: "",
    icon: "",
    embedUrl: "",
    tileOrder: "",
    valuePillar: "",
    applicationSecurityId: "",
    adclient: "",
    description: "",
    externalUrl: "",
    appRegId: "",
    newTab: false,
  };

  //const LOCAL_STORAGE_KEY = "app_isNewTab";
  const [showSucessModal, setShowSucessModal] = useState(false);
  const [appTypeactiveClass, setAppTypeActiveClass] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchInput, setSearchInput] = useState<string>("");
  const [existingAppActive, setExistingAppActive] = useState("");
  const [appDetails, setAppDetails] = useState<Dict>({});
  const [allApplicationType, setAllApplicationType] = useState<any>([]);
  const [PillarData, setValuePillarData] = useState<any>([]);
  const [valuePillar, setValuePillar] = useState<any>();
  const [addApplicationList, setAddApplicationList] = useState<any>([]);
  const [typeId, setTypeId] = useState<any>([]);
  const [readOnly, setReadOnly] = useState(true);
  const [NewApp, setNewApp] = useState(false);
  const [disableForm, setDisableForm] = useState(true);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const appGeneralTextData = React.useContext(AppGeneralTextContext);
  const { general_text_data }: any = appGeneralTextData;
  const [formInit, setFormInit] = useState(initVals);
  const [defaultForm, setDefaultForm] = useState(initVals);
  const [permitted, setPermitted] = useState(true);
  const { showNotification } = useNotification();
  const [clientId, setClientId] = useState<string[]>([]);
  const [allRoles, setAllRoles] = useState<any>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    callGetAllApplications();
    callAllApplicationType();
  }, []);

  useEffect(() => {
    if (clientId.length > 0) {
      callGetUserRoles();
    }
  }, [clientId]);

  useEffect(() => {
    let selectedApp =
      PillarData.lenght > 0
        ? PillarData.find((app: any) => app.pillarName === appTypeactiveClass)
        : appTypeactiveClass;
    setValuePillar(
      selectedApp.pillarName ? selectedApp.pillarName : appTypeactiveClass
    );
  }, [appTypeactiveClass, PillarData]);

  useEffect(() => {
    const applicationNames =
      allRoles?.length > 0
        ? allRoles.map((item: any) => item.applicationName)
        : [];

    // Check if existingAppActive is in the list of application names
    if (applicationNames.includes(existingAppActive)) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [existingAppActive, allRoles]);

  const callGetAllApplications = async () => {
    try {
      const resp = await getAllApplications();

      if (resp.length > 0) {
        let applications: AllApplications[] = resp;
        const clientIdsArray = applications.map((app) => app.client_id);
        setClientId(clientIdsArray);
      }
    } catch (err: any) {
      if (err.response && err.response.status === 403) {
        setPermitted(false);
        setShowPermissionModal(true);
      }
    }
  };

  const callGetUserRoles = async () => {
    try {
      const response = await getAllRoles(clientId);

      response && setAllRoles(response.data);
    } catch (err: any) {
      if (err.response && err.response.status === 403) {
        setPermitted(false);
        setShowPermissionModal(true);
      } else {
        console.log(err);
      }
    }
  };
  const handleCancel = () => {
    setReadOnly(true);
    setDisableForm(true);
    setFormInit(defaultForm);
  };

  const handleNewApplicationClick = () => {
    setFormInit(initVals);
    setReadOnly(false);
    setDisableForm(false);
    setNewApp(true);
  };
  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
    const mockEvent = {
      preventDefault: () => {},
    };
    handleLinkClick(mockEvent, appTypeactiveClass, typeId);
  };
  async function handleConfirmDelete() {
    const selectedAppList: any = formInit.appRegId;
    const selectedApp: any = formInit.applicationName;

    try {
      let response = await deleteAppltnConfig(selectedAppList);
      if (response) {
        setShowDeleteConfirmation(false);
        showNotification([
          buildNotification(selectedApp + " is deleted sucessfully", "success"),
        ]);

        const mockEvent = {
          preventDefault: () => {},
        };

        handleLinkClick(mockEvent, appTypeactiveClass, typeId);
      }
    } catch (error: any) {
      if (error.response.data) {
        showNotification([buildNotification(error.response.data, "alarm")]);
      } else {
        showNotification([buildNotification("Failed to Delete", "alarm")]);
      }
    }
  }
  const handleEditClick = () => {
    setReadOnly(false);
    setNewApp(false);
    setDisableForm(false);
  };

  async function callAllApplicationType() {
    try {
      const result = await getAllApplicationType();
      if (result) {
        setAllApplicationType(result);
        const _data = result[0];
        const mockEvent = {
          preventDefault: () => {},
        };
        handleLinkClick(
          mockEvent,
          _data.applicationTypeName,
          _data.applicationTypeId
        );
      }
      if (result.length > 0) {
        const resp = await getAllPillarData();
        setValuePillarData(resp);
      }
    } catch (error) {}
  }
  const handleSearchChange = (event: any) => {
    if (event) {
      setSearchTerm(event);
      setSearchInput(event);
    } else {
      setSearchTerm("");
      setSearchInput("");
    }
  };
  const isSubmit = (submitStatus: any) => {
    if (!submitStatus) {
      setShowSucessModal(!showSucessModal);
      setTimeout(() => {
        setShowSucessModal(false);
      }, 3000);
    }
  };

  const handleLinkClick = async (
    e: any,
    appTypeactiveClass: any,
    typeId: any
  ) => {
    e.preventDefault();
    try {
      const response = await getAllApplicationConfigurations(typeId);
      if (!response) {
        throw new Error("Network response was not ok");
      }
      const sortedResponse = response.sort((a: any, b: any) => {
        if (a.tileOrder === b.tileOrder) {
          return a.applicationName.localeCompare(b.applicationName);
        }
        return a.tileOrder - b.tileOrder;
      });

      const dict: Dict = {};
      sortedResponse.forEach((item: any) => {
        dict[item.applicationName] = item;
      });

      setAppDetails(dict);
      const nameList: any[] = sortedResponse.map(
        (dict: any) => dict["applicationName"]
      );
      setAddApplicationList(nameList);
      setExistingAppActive(nameList[0]);
      setFormInit(sortedResponse[0]);
      setDefaultForm(sortedResponse[0]);
    } catch (error) {}
    setTypeId(typeId);
    setAppTypeActiveClass(appTypeactiveClass);
  };

  const handleAppLinkClick = (e: any, link_name: any) => {
    e.preventDefault();
    setExistingAppActive(link_name);
    setFormInit(appDetails[link_name]);
    setDisableForm(true);
  };

  const isChecked = (name: string) => {
    const isAllowRole = PillarData.find(
      (item: any) => item.pillarName === name
    );
    return isAllowRole ? isAllowRole.allowRoleCreation : false;
  };
  const handleSubmitForm = async (values: any) => {
    const apptypeId = typeId;
    values.applicationTypeId = appTypeactiveClass;
    try {
      const payload = {
        appRegId: values.appRegId,
        name: values.applicationName,
        description: values.description,
        applicationTypeId: apptypeId,
        // applicationTypeName: values.applicationTypeId,
        embedUrl: values.embedUrl,
        icon: values.icon,
        // newTab: isNewTab,
        newTab: values.newTab,
        valuePillar: valuePillar,
        tileOrder: values.tileOrder,
        externalUrl: values.externalUrl,
        ...(NewApp ? {} : { appRegId: values.appRegId }),
      };

      const response: any = NewApp
        ? await createAppltnConfig(payload)
        : await updateAppltnConfig(payload);

      if (response) {
        const msg_status = NewApp ? "Created" : "Updated";
        showNotification([
          buildNotification(
            "Application: " +
              values.applicationName +
              " is " +
              msg_status +
              " Successfully!!",
            "success"
          ),
        ]);
        setDisableForm(true);
        setReadOnly(true);
        setNewApp(false);
        const mockEvent = {
          preventDefault: () => {},
        };
        handleLinkClick(mockEvent, appTypeactiveClass, apptypeId);
      } else {
      }
      isSubmit(true);
    } catch (error: any) {
      if (error.response.data) {
        showNotification([buildNotification(error.response.data, "alarm")]);
      } else {
        showNotification([
          buildNotification("Failed to create a new application", "alarm"),
        ]);
      }
      isSubmit(false);
    }
  };
  return (
    <div className="application-registration">
      {!permitted && showPermissionModal && (
        <div className="modal-overlay">
          <div className="modal red-border">
            <div className="modal-content">
              <button
                className="modal-close"
                onClick={() => {
                  navigate("/home");
                }}
              >
                &times;
              </button>
              <p>
                You don’t have the required permissions to access Platform
                Administration Application. Please contact the administrator.
              </p>
            </div>
          </div>
        </div>
      )}
      {permitted === true && (
        <div>
          <div className="app-reg-page-title">
            <h1>{general_text_data.add_application}</h1>
          </div>

          {showDeleteConfirmation && (
            <>
              <div
                className="modal"
                style={{
                  fontSize: "14px",
                  width: "26%",
                  marginLeft: "25%",
                  marginTop: "8.8%",
                  position: "absolute",
                  zIndex: 5,
                }}
              >
                <Dialog
                  showCloseButton={true}
                  closeOnEscape={true}
                  closeOnLostFocus={true}
                  hideBackground={true}
                  dimBackground={true}
                  title="Are you sure you want to delete the selected rows?"
                  standardButtonsOnBottom={[
                    {
                      text: "Cancel",
                      type: "discreet-blue",
                      handler: handleCancelDelete,
                    },
                    {
                      text: "Delete",
                      type: "primary-blue",
                      handler: handleConfirmDelete,
                    },
                  ]}
                />
              </div>
            </>
          )}
          {disableForm && (
            <div className="edit-add-application-btn">
              <MUIButton
                label="New Application"
                onClickButton={handleNewApplicationClick}
                size={32}
                variant="secondary"
              />
              <MUIButton
                label="Edit"
                onClickButton={handleEditClick}
                size={32}
                variant="secondary"
              />

              <div
                title={
                  isButtonDisabled
                    ? "You can't delete this until the associated roles and users are deleted"
                    : ""
                }
              >
                <MUIButton
                  label="Delete"
                  onClickButton={() => setShowDeleteConfirmation(true)}
                  size={32}
                  variant="secondary"
                  className={
                    isButtonDisabled ? "disabled-btn" : "secondary-btn"
                  }
                  iconName="abb/trash"
                  disabled={isButtonDisabled}
                />
              </div>
            </div>
          )}

          <div className="content-area">
            <div className="first-container">
              <div className="content-sub-heading">
                <h4>{general_text_data.application_types}</h4>
              </div>
              <div className="app-types-container">
                {allApplicationType &&
                  allApplicationType.map((item: any) => {
                    return (
                      <div
                        className={`app-type-name ${
                          appTypeactiveClass === item.applicationTypeName
                            ? "activeClass"
                            : ""
                        }`}
                        onClick={(e) =>
                          handleLinkClick(
                            e,
                            item.applicationTypeName,
                            item.applicationTypeId
                          )
                        }
                      >
                        <Link to={""}>{item.applicationTypeName}</Link>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="second-container">
              <div className="sticky-heading">
                <div className="content-sub-heading">
                  <h4>Application List</h4>
                </div>
                <div className="search-btn-app-list">
                  <ABB.Input
                    dataType="text"
                    icon="abb/search"
                    onValueChange={handleSearchChange}
                    placeholder="Search Application here"
                    value={searchTerm}
                  />
                </div>
              </div>
              <div className="app-list-items">
                {addApplicationList &&
                  addApplicationList
                    .filter((app: any) =>
                      app.toLowerCase().includes(searchInput.toLowerCase())
                    )
                    .map((item: any) => {
                      return (
                        <div
                          className={`app-name ${
                            existingAppActive === item ? "app-active" : ""
                          }`}
                          onClick={(e) => handleAppLinkClick(e, item)}
                        >
                          <Link to={""}>{item}</Link>
                        </div>
                      );
                    })}
              </div>
            </div>
            <div className="formik-section">
              <Formik
                initialValues={formInit}
                enableReinitialize={true}
                onSubmit={async (values, { setSubmitting }) => {
                  const apptypeId = typeId;
                  values.applicationTypeId = appTypeactiveClass;
                  try {
                    const payload = {
                      appRegId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                      name: values.applicationName,
                      description: values.description,
                      applicationTypeId: apptypeId,
                      applicationTypeName: values.applicationTypeId,
                      embedUrl: values.embedUrl,
                      icon: values.icon,
                      // newTab: isNewTab,
                      newTab: values.newTab,
                      valuePillar: valuePillar,
                      tileOrder: values.tileOrder,
                      externalUrl: values.externalUrl,
                      ...(NewApp ? {} : { appRegId: values.appRegId }),
                    };

                    const response: any = NewApp
                      ? await createAppltnConfig(payload)
                      : await updateAppltnConfig(payload);

                    if (response) {
                      const msg_status = NewApp ? "Created" : "Updated";
                      showNotification([
                        buildNotification(
                          "Application: " +
                            values.applicationName +
                            " is " +
                            msg_status +
                            " Successfully!!",
                          "success"
                        ),
                      ]);
                      setDisableForm(true);
                      setReadOnly(true);
                      setNewApp(false);
                      const mockEvent = {
                        preventDefault: () => {},
                      };
                      handleLinkClick(mockEvent, appTypeactiveClass, apptypeId);
                    } else {
                    }
                    isSubmit(true);
                  } catch (error: any) {
                    isSubmit(false);
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  resetForm,
                }) => (
                  <Form className="form-action" onSubmit={handleSubmit}>
                    <div className="third-container">
                      <div className="content-sub-heading">
                        <h4>Application Details</h4>
                      </div>

                      <div className="grid-container  gap-20">
                        <div className="col-5 input-group">
                          <div className="grid-container">
                            <div className="col-5">
                              <div className="input-label">
                                {general_text_data.application_name}{" "}
                                <span className="asterisk">*</span>:
                              </div>
                            </div>
                            <div className="col-7">
                              <div className="input-field">
                                <UseAbbField
                                  type="text"
                                  value={values.applicationName}
                                  placeholder=""
                                  label="Application Name"
                                  name="applicationName"
                                  onChange={handleChange}
                                  component={""}
                                  readOnly={readOnly}
                                  disabled={disableForm}
                                />
                                {errors.applicationName &&
                                touched.applicationName ? (
                                  <div className="formik-error">
                                    {errors.applicationName}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-7 input-group">
                          <div className="grid-container">
                            <div className="col-4">
                              <div className="input-label">
                                {general_text_data.application_icon}{" "}
                                <span className="asterisk">*</span>:
                              </div>
                            </div>
                            <div className="col-8">
                              <div className="input-field">
                                <UseAbbField
                                  type="text"
                                  value={values ? values.icon : ""}
                                  placeholder=""
                                  label="Application Icon"
                                  name="icon"
                                  onChange={handleChange}
                                  component={""}
                                  readOnly={readOnly}
                                  disabled={disableForm}
                                />
                                {errors.icon && touched.icon ? (
                                  <div className="formik-error">
                                    {errors.icon}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="grid-container gap-20">
                        <div className="col-5 input-group">
                          <div className="grid-container">
                            <div className="col-5 input-group">
                              <div className="input-label">
                                {general_text_data.application_type}:
                              </div>
                            </div>
                            <div className="col-7 input-group">
                              <div className="input-disable">
                                <UseAbbField
                                  type="text"
                                  value={
                                    appTypeactiveClass ? appTypeactiveClass : ""
                                  }
                                  placeholder=""
                                  label="Application Type"
                                  name="application_type"
                                  component={""}
                                  onChange={handleChange}
                                  readOnly={readOnly}
                                  disabled={disableForm}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-7 input-group">
                          <div className="grid-container">
                            <div className="col-4 input-group">
                              <div className="input-label">
                                {general_text_data.embedded_URL}:
                              </div>
                            </div>
                            <div className="col-8 input-group">
                              <div className="input-field">
                                <UseAbbField
                                  type="text"
                                  value={values ? values.embedUrl : ""}
                                  label="Embeded URL"
                                  placeholder=""
                                  name="embedUrl"
                                  onChange={handleChange}
                                  component={""}
                                  readOnly={readOnly}
                                  disabled={disableForm}
                                />
                                {errors.embedUrl && touched.embedUrl ? (
                                  <div className="formik-error">
                                    {errors.embedUrl}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="grid-container gap-20">
                        <div className="col-5 input-group">
                          <div className="grid-container">
                            <div className="col-5 input-group">
                              <div className="input-label">
                                {general_text_data.value_pillar}{" "}
                                <span className="asterisk">*</span>:
                              </div>
                            </div>
                            <div className="col-7 input-group">
                              <div className="input-disable">
                                <UseAbbField
                                  type="text"
                                  value={valuePillar ? valuePillar : ""}
                                  placeholder=""
                                  label="value_pillar"
                                  name="valuePillar"
                                  component={""}
                                  readOnly={readOnly}
                                  disabled={disableForm}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-7 input-group">
                          <div className="grid-container">
                            <div className="col-4 input-group">
                              <div className="input-label">
                                {general_text_data.externalUrl}:
                              </div>
                            </div>
                            <div className="col-8 input-group">
                              <div className="input-field">
                                <UseAbbField
                                  type="text"
                                  value={values ? values.externalUrl : ""}
                                  placeholder=""
                                  name="externalUrl"
                                  onChange={handleChange}
                                  className="width-500"
                                  component={""}
                                  readOnly={readOnly}
                                  disabled={disableForm}
                                />
                                {errors.externalUrl && touched.externalUrl ? (
                                  <div className="formik-error">
                                    {errors.externalUrl}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="grid-container gap-20">
                        <div className="col-5 input-group">
                          <div className="grid-container">
                            <div className="col-5 input-group">
                              <div className="input-label">
                                {general_text_data.tile_order}{" "}
                                <span className="asterisk">*</span>:
                              </div>
                            </div>
                            <div className="col-7 input-group">
                              <div className="input-field">
                                <UseAbbField
                                  type="text"
                                  value={values.tileOrder}
                                  placeholder=""
                                  name="tileOrder"
                                  onChange={(e: any) => {
                                    const value = e.target.value;
                                    if (/^\d*$/.test(value)) {
                                      // Ensure the value is an integer
                                      handleChange(e);
                                    }
                                  }}
                                  component={""}
                                  readOnly={readOnly}
                                  disabled={disableForm}
                                />
                                {errors.tileOrder && touched.tileOrder ? (
                                  <div className="formik-error">
                                    {errors.tileOrder}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-7 input-group">
                          <div className="grid-container">
                            <div className="col-4 input-group">
                              <div className="input-label">
                                {general_text_data.open_in_new_tab}:
                              </div>
                            </div>
                            <div className="col-8 input-group">
                              <div className="input-field">
                                <input
                                  type="checkbox"
                                  name="newTab"
                                  checked={values.newTab}
                                  onChange={handleChange}
                                  disabled={disableForm}
                                  className="input-checkbox"
                                />
                                {errors.newTab && touched.newTab ? (
                                  <div className="formik-error">
                                    {errors.newTab}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="grid-container gap-20">
                        <div className="col-5 input-group">
                          <div className="grid-container">
                            <div className="col-5 input-group">
                              <div className="input-label">
                                {general_text_data.description}:
                              </div>
                            </div>
                            <div className="col-7 input-group">
                              <div className="input-field">
                                <UseAbbField
                                  type="text-area"
                                  value={values ? values.description : ""}
                                  placeholder=""
                                  label="Description"
                                  name="description"
                                  onChange={handleChange}
                                  component="textarea"
                                  readOnly={readOnly}
                                  disabled={disableForm}
                                />
                                {errors.description && touched.description ? (
                                  <div className="formik-error">
                                    {errors.description}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-7 input-group">
                          {valuePillar !== "Platform Administrator" && (
                            <div className="grid-container">
                              <div className="col-4 input-group">
                                <div className="input-label">
                                  {general_text_data.allow_custom_role}:
                                </div>
                              </div>
                              <div className="col-8 input-group">
                                <div className="input-field">
                                  <ABB.Checkbox
                                    value={isChecked(valuePillar)}
                                    sizeClass="small"
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {!disableForm && (
                      <div className="form-cta-btn">
                        <div>
                         
                          <MUIButton
                            label={general_text_data.cancel}
                            onClickButton={() => {
                              handleCancel();
                              resetForm();
                            }}
                            size={32}
                            variant="secondary"
                            disabled={isSubmitting}
                          />
                        </div>
                        <div>
                        <MUIButton
                            label={NewApp === true
                              ? general_text_data.save
                              : general_text_data.update}
                            onClickButton={() => {
                              handleSubmitForm(values);
                            }}
                            size={32}
                            variant="primary"
                            disabled={
                              isInvalid(values.applicationName) ||
                              isInvalid(values.tileOrder) ||
                              isInvalid(values.icon)
                            }
                          />
                         
                        </div>
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default AddApplication;

import { jwtDecode } from "jwt-decode";

export const getDropdownValue = (label: string, value: string) => {
  return [
    {
      label: label,
      value: value,
    },
  ];
};

export const buildQueryParams = (params: { [key: string]: any }) => {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
};

export const buildNotification = (
  message: string,
  type: "success" | "info" | "warn" | "alarm"
) => {
  return {
    id: Math.random() > 0.7,
    type: "banner",
    severity: type,
    timeout: 3000,
    text: message,
  };
};

// Cliendside Pagination
export const buildPaginationData = (pageNum: number = 1, pageSize: number = 5, data: any) => {
  const indexOfLastItem = pageNum * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentData = data
    ? data?.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  return currentData;
};

//User Role

export const getUserDataFromToken = () => {
  const access_token = localStorage.getItem("access_token")!.toString();

  try {
    const getUserData: any = jwtDecode(access_token);
    return getUserData;
  } catch (error) {
    console.error("Error decoding token:", error);
  }
};


import  { createContext, useState, useContext, useMemo, useEffect } from "react";

const UserContext = createContext<any>(null);

export const UserProvider = (props: any) => {
  const { children } = props;
  // Initialize the state with data from localStorage
  const [userData, setUserData] = useState<any>(() => {
    const storedUserData = localStorage.getItem('userTokenData');
    return storedUserData ? JSON.parse(storedUserData) : null;
  });

  // Update localStorage whenever userData changes
  useEffect(() => {
    if (userData !== null) {
      localStorage.setItem('userTokenData', JSON.stringify(userData));
    }
  }, [userData]);

  const contextValue = useMemo(
    () => ({ userData, setUserData }),
    [userData, setUserData]
  );
  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
